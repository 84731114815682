<template>
  <div>
    <v-card>
      <v-overlay
        :absolute="true"
        :value="loading"
        opacity="0.5"
        color="#c0c0c0"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
            >
              <v-text-field
                v-model="name"
                :rules="nameRules"
                :label="$t('UserPage.UserName')"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
            >
              <v-text-field
                v-model="email"
                :name="Math.random()"
                :rules="emailRules"
                :label="$t('UserPage.Email')"
                :error-messages="errorMessages"
                required
                @change="checkMail(email)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="8"
              xl="8"
              md="8"
              sm="8"
            >
              <v-select
                ref="role"
                v-model="role"
                :items="roles"
                :rules="roleRules"
                :label="$t('UserPage.Role')"
                item-text="displayName"
                item-value="id"
                required
                return-object
              >
                <template v-slot:item="{item}">
                  <v-row>
                    <v-col
                      cols="12"
                      xl="12"
                      md="12"
                      sm="12"
                    >
                      {{ $t(`UserPage.Roles.${item.id}`) }}
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="{item}">
                  <v-row>
                    <v-col
                      cols="12"
                      xl="12"
                      md="12"
                      sm="12"
                    >
                      {{ $t(`UserPage.Roles.${item.id}`) }}
                    </v-col>
                  </v-row>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="4"
              xl="4"
              md="4"
              sm="4"
            >
              <v-btn
                color="primary"
                class="mr-4 mt-5"
                plain
                x-small
                :disabled="role === null"
                @click="openDialog"
              >
                <v-icon
                  right
                  dark
                  class="mr-2"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
                {{ $t('UserPage.EditRules') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="role">
            <v-col
              v-if="role.name === 'ADVERTISERS'"
              cols="12"
              xl="12"
              md="12"
              sm="12"
            >
              <v-text-field
                v-model="agency"
                :label="$t('UserPage.Agency')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
            >
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                :name="Math.random()"
                :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
                :label="$t('UserPage.Password')"
                :type="showPassword ? 'text' : 'password'"
                required
                @focus="handleType"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
            >
              <v-select
                v-model="status"
                :items="statuses"
                :item-text="$i18n.locale === 'ru' ? 'nameRu' : 'nameEn'"
                item-value="value"
                :label="$t('UserPage.Status')"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="role">
            <v-col
              v-if="role.name === 'ADVERTISERS'"
              cols="12"
              xl="12"
              md="12"
              sm="12"
            >
              <v-select
                ref="role"
                v-model="domain"
                :items="domains"
                :rules="domainRules"
                :label="$t('UserPage.TechDomain')"
                item-text="domain"
                item-value="id"
                required
                return-object
              >
                <template v-slot:item="{item}">
                  <v-row>
                    <v-col
                      cols="12"
                      xl="12"
                      md="12"
                      sm="12"
                    >
                      {{ item.domain }}
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="{item}">
                  <v-row>
                    <v-col
                      cols="12"
                      xl="12"
                      md="12"
                      sm="12"
                    >
                      {{ item.domain }}
                    </v-col>
                  </v-row>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                class="mr-4"
                @click="saveUser()"
              >
                {{ $t('UserPage.Save') }}
              </v-btn>

              <v-btn
                color="primary"
                class="mr-4"
                outlined
                @click="back()"
              >
                {{ $t('UserPage.Cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="isDialogVisible"
      width="800"
    >
      <v-card>
        <v-card-title>
          {{ $t('UserPage.UserRules') }}
        </v-card-title>

        <v-card-text>
          <v-treeview
            ref="myTreeview"
            v-model="selectedPrivileges"
            :items="privilegesTree"
            selection-type="independent"
            :item-text="$i18n.locale === 'ru'? 'displayName' : `displayName${$ucFirst($i18n.locale)}`"
            item-key="id"
            selectable
            item-disabled="isDisabled"
            :on-icon="icons.mdiCheck"
            :off-icon="icons.mdiCloseCircleOutline"
            :indeterminate-icon="icons.mdiCheck"
            open-all
          >
            <template v-slot:append="{ item }">
              <v-chip
                color="primary"
                small
              >
                {{ item.name }}
              </v-chip>
            </template>
          </v-treeview>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="closeDialog()"
          >
            {{ $t('BtnClose') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="showMessage"
      :timeout="timeout"
      :color="color"
    >
      {{ messageText }}
    </v-snackbar>
  </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiCheck, mdiCloseCircleOutline, mdiEye, mdiEyeOff, mdiPencil } from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiEye,
        mdiEyeOff,
        mdiPencil,
        mdiCheck,
        mdiCloseCircleOutline,
      },
      dataFromDb: [],
      domains: [],
      domain: {},
      loading: false,
      isDialogVisible: false,
      errorMessages: '',
      showMessage: false,
      timeout: 5000,
      isFree: true,
      color: 'success',
      messageText: '',
      dialog: false,
      valid: true,
      name: '',
      map: {},
      privileges: [],
      privilegesTree: [],
      selectedPrivileges: [],
      cabninetsIds: [],
      showPassword: true,
      nameRules: [v => !!v || this.$t('UserPage.Validation.NameRequired')],
      email: '',
      domainRules: [v => !!v || this.$t('UserPage.Validation.DomainRequired')],
      roleRules: [
        v => !!v || this.$t('UserPage.Validation.RoleRequired'),
        () => this.checkCabinetsRules() || this.$t('UserPage.Validation.CabinetRequired'),
      ],
      emailRules: [
        v => !!v || this.$t('UserPage.Validation.EmailRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('UserPage.Validation.EmailIncorrect'),
      ],
      role: null,
      roles: [],
      agency: '',
      passwordRules: [
        v => !!v || this.$t('UserPage.Validation.PasswordRequired'),
        v => (v || '').length >= 6 || this.$t('UserPage.Validation.PasswordLength'),
      ],
      password: '',
      status: 'active',
      statuses: [
        { nameEn: 'Active', nameRu: 'Активный', value: 'active' },
        { nameEn: 'Inactive', nameRu: 'Не активный', value: 'inactive' },
      ],
    }
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: this.$t('UserPage.UserName'), value: 'name' },
        { text: this.$t('UserPage.Email'), value: 'email' },
        { text: this.$t('UserPage.Role'), value: 'role' },
        { text: this.$t('UserPage.Status'), value: 'status' },
        { text: this.$t('UserPage.Actions'), value: 'actions' },
      ]
    },
  },
  watch: {
    role() {
      this.getPrivileges()
    },
    selectedPrivileges() {
      this.$refs.role.validate()
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    handleType(event) {
      const { srcElement, type } = event
      const { value } = srcElement

      if (type === 'focus' && !value) {
        this.showPassword = false
      }
    },
    saveUser() {
      const valid = this.$refs.form.validate()
      // eslint-disable-next-line no-useless-return
      if (!valid) return

      // eslint-disable-next-line prefer-const
      let privileges = []

      this.selectedPrivileges.forEach(item => {
        // eslint-disable-next-line prefer-const
        let rule = this.privileges.find(e => e.id === item)
        privileges.push({ id: item, name: rule.name })
      })

      const params = {
        name: this.name,
        email: this.email,
        role: this.role.name,
        privileges,
        password: this.password,
        status: this.status,
      }
      if (this.role.name === 'ADVERTISERS') {
        params.agency = this.agency
        params.appDomains = this.domain
      }

      this.$http.post(`${this.$apiBaseURL}/user`, params).then(() => {
        // тут сохраняем привязку.
        this.$router.push({ name: 'users-list' })
      })
    },
    checkMail(email) {
      const params = {
        params: {
          email,
        },
      }
      this.$http.get(`${this.$apiBaseURL}/user/check-email`, params).then(response => {
        if (!response.data) {
          this.errorMessages = this.$t('UserPage.Validation.EmailExists')
        } else {
          this.errorMessages = ''
        }
      })
    },
    back() {
      this.$router.push({ name: 'users-list' })

      // this.$router.push({ name: 'campaigns-list', params: { advertiser_id: advertiserId } })
    },
    getData() {
      this.loading = true
      const params = {
        params: {
          userId: this.$getUserId(),
        },
      }

      // this.$http.get('/advertisers/table_data', params) `${this.$apisBaseURL}/publisher/item`

      this.$http
        .get(`${this.$apiBaseURL}/roles/for/create/user`, params)
        .then(response => {
          this.roles = response.data !== null ? response.data : []
        })
        .then(() => {
          this.$http.get(`${this.$apiBaseURL}/app/domains`, params).then(resp => {
            this.domains = resp.data !== null ? resp.data : []
            if (this.domains.length > 0) {
              // eslint-disable-next-line prefer-destructuring
              this.domain = this.domains[0]
            }
            this.loading = false
          })
        })
    },
    getPrivileges() {
      this.loading = true
      const params = {
        params: {
          roleId: this.role.id,
        },
      }
      this.$http
        .get(`${this.$apiBaseURL}/user/privileges`, params)
        .then(response => {
          this.privileges = response.data !== null ? response.data : []
          this.loading = false
          this.privilegesTree = []
          this.selectedPrivileges = []
          let i

          for (i = 0; i < this.privileges.length; i += 1) {
            this.map[this.privileges[i].id] = i // initialize the map
            this.privileges[i].children = [] // initialize the children
          }
        })
        .then(() => {
          let node
          let i

          for (i = 0; i < this.privileges.length; i += 1) {
            node = this.privileges[i]
            if (node.parentId !== 0) {
              // if you have dangling branches check that map[node.parentId] exists
              this.privileges[this.map[node.parentId]].children.push(node)
            } else {
              this.privilegesTree.push(node)
            }
          }

          this.privileges.forEach(item => {
            if (item.isChecked) {
              this.selectedPrivileges.push(item.id)
            }
          })
        })
        .then(() => {
          this.cabninetsIds = this.getAvaibleCabinets()
        })
    },
    openDialog() {
      this.isDialogVisible = true
      if (this.$refs.myTreeview) {
        this.$refs.myTreeview.updateAll(true)
      }
    },
    closeDialog() {
      this.isDialogVisible = false
    },
    getAvaibleCabinets() {
      const cabinets = this.privilegesTree.find(c => c.name === 'ACCESS_PERSONAL_CABINET')
      const cabninetsIds = []
      cabinets.children.forEach(element => {
        cabninetsIds.push(element.id)
      })

      return cabninetsIds
    },
    checkCabinetsRules() {
      const selectedCabnets = this.selectedPrivileges.filter(e => this.cabninetsIds.includes(e))
      if (this.role) {
        if (this.role.name === 'MANAGERS') {
          if (selectedCabnets.length === 1) {
            return true
          }

          return false
        }

        return true
      }

      return true
    },
  },
}
</script>
